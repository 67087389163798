<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />

      </b-col>
    </b-row>
    <b-row class="match-height" v-if="statistics">
      <b-col cols="12" class="my-1">
        <h2>
          آمار اقامتگاه
        </h2>
        {{$route.params.name}}
      </b-col>

      <b-col
          xl="2"
          md="4"
          sm="6"
      >
        <div class="card p-2 d-flex flex-column justify-content-center align-items-center">
          <div>
            <feather-icon
                icon="CornerLeftDownIcon"
                size="28"
            />
          </div>
          <div class="my-1">
            تعداد درخواست رزرو :
          </div>
          <div class="font-weight-bold" v-if="statistics.total_count_orders>0">
            <strong>{{statistics.total_count_orders}} </strong>
          </div>
          <div v-else class="font-weight-bold">
            0
          </div>
        </div>
      </b-col>
      <b-col
          xl="2"
          md="4"
          sm="6"
      >
        <div class="card p-2 d-flex flex-column justify-content-center align-items-center">
          <div>
            <feather-icon
                icon="CornerLeftDownIcon"
                size="28"
            />
          </div>
          <div class="my-1">
            تعداد  رزروهای موفق :
          </div>
          <div class="font-weight-bold" v-if="statistics.total_count_success>0">
            <strong>{{statistics.total_count_success}} </strong>
          </div>
          <div v-else class="font-weight-bold">
            0
          </div>
        </div>
      </b-col>




      <b-col
          xl="2"
          md="4"
          sm="6"
      >
        <div class="card p-2 d-flex flex-column justify-content-center align-items-center">
          <div>
            <feather-icon
                icon="DollarSignIcon"
                size="28"
            />
          </div>
          <div class="my-1">
            مجموع هزینه پرداختی :
          </div>
          <div class="font-weight-bold" v-if="statistics.total_final_price_success>0">
            <strong>
              {{
                numberFormat(statistics.total_final_price_success)
              }}
              تومان
            </strong>
          </div>
          <div v-else>
            0
          </div>
        </div>
      </b-col>
      <b-col
          xl="2"
          md="4"
          sm="6"
      >
        <div class="card p-2 d-flex flex-column justify-content-center align-items-center">
          <div>
            <feather-icon
                icon="DollarSignIcon"
                size="28"
            />
          </div>
          <div class="my-1">
            مجموع پرداختی به میزبان :
          </div>
          <div class="font-weight-bold" v-if="statistics.total_commission_host_success>0">
            <strong>
              {{
                numberFormat(statistics.total_commission_host_success)
              }}
              تومان
            </strong>
          </div>
          <div v-else>
            0
          </div>
        </div>
      </b-col>

      <b-col
          xl="2"
          md="4"
          sm="6"
      >
        <div class="card p-2 d-flex flex-column justify-content-center align-items-center">
          <div>
            <feather-icon
                icon="DollarSignIcon"
                size="28"
            />
          </div>
          <div class="my-1">
            مجموع کمیسیون سایت :
          </div>
          <div class="font-weight-bold" v-if="statistics.total_total_commission_site_roomtoor_success>0">
            <strong>
              {{
                numberFormat(statistics.total_total_commission_site_roomtoor_success)
              }}
              تومان
            </strong>
          </div>
          <div v-else>
            0
          </div>
        </div>
      </b-col>

<!--      <b-col
          xl="2"
          md="4"
          sm="6"
      >
        <div class="card p-2 d-flex flex-column justify-content-center align-items-center">
          <div>
            <feather-icon
                icon="HomeIcon"
                size="28"
            />
          </div>
          <div class="my-1">
            مجموع تخفیف داده شده :
          </div>
          <div class="font-weight-bold" v-if="statistics.total_final_price_with_discount_success>0">
            <strong>
              {{
                numberFormat(statistics.total_final_price_with_discount_success)
              }}
              تومان
            </strong>
          </div>
          <div v-else>
            0
          </div>
        </div>
      </b-col>-->


      <b-col
          xl="2"
          md="4"
          sm="6"
      >
        <div class="card p-2 d-flex flex-column justify-content-center align-items-center">
          <div>
            <feather-icon
                icon="UsersIcon"
                size="28"
            />
          </div>
          <div class="my-1">
            مجموع مهمان ها (بزرگسال و کودک) :
          </div>
          <div class="font-weight-bold" v-if="statistics.total_guest_adult>0">
            <strong>{{statistics.total_guest_adult + statistics.total_guest_child}} نفر </strong>
          </div>
          <div v-else class="font-weight-bold">
            0
          </div>
        </div>
      </b-col>

      <b-col
          xl="2"
          md="4"
          sm="6"
      >
        <div class="card p-2 d-flex flex-column justify-content-center align-items-center">
          <div>
            <feather-icon
                icon="UsersIcon"
                size="28"
            />
          </div>
          <div class="my-1">
            مجموع بزرگسال :
          </div>
          <div class="font-weight-bold" v-if="statistics.total_guest_adult>0">
            <strong>{{statistics.total_guest_adult}}  نفر</strong>
          </div>
          <div v-else class="font-weight-bold">
            0
          </div>
        </div>
      </b-col>
      <b-col
          xl="2"
          md="4"
          sm="6"
      >
        <div class="card p-2 d-flex flex-column justify-content-center align-items-center">
          <div>
            <feather-icon
                icon="UserIcon"
                size="28"
            />
          </div>
          <div class="my-1">
            مجموع کودک (2 تا 12) :
          </div>
          <div class="font-weight-bold" v-if="statistics.total_guest_child>0">
            <strong>{{statistics.total_guest_child}} نفر </strong>
          </div>
          <div v-else class="font-weight-bold">
            0
          </div>
        </div>
      </b-col>

      <b-col
          xl="2"
          md="4"
          sm="6"
      >
        <div class="card p-2 d-flex flex-column justify-content-center align-items-center">
          <div>
            <feather-icon
                icon="UserIcon"
                size="28"
            />
          </div>
          <div class="my-1">
            مجموع نوزاد :
          </div>
          <div class="font-weight-bold" v-if="statistics.total_guest_baby>0">
            <strong>{{statistics.total_guest_baby}} نفر </strong>
          </div>
          <div v-else class="font-weight-bold">
            0
          </div>
        </div>
      </b-col>

      <b-col
          xl="2"
          md="4"
          sm="6"
      >
        <div class="card p-2 d-flex flex-column justify-content-center align-items-center">
          <div>
            <feather-icon
                icon="UserIcon"
                size="28"
            />
          </div>
          <div class="my-1">
            مجموع حیوان :
          </div>
          <div class="font-weight-bold" v-if="statistics.total_guest_pet>0">
            <strong>{{statistics.total_guest_pet}} نفر </strong>
          </div>
          <div v-else class="font-weight-bold">
            0
          </div>
        </div>
      </b-col>
    </b-row>



  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormRadio,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";

export default {
  components: {
    StatisticCardVertical,
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    BFormRadio
  },
  data() {
    return {
      perPage: 10,
      page: 1,
      statistics: [],
      residences: null,
      cars: null,
      tours: null,
      experiences: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,

      OpenIndicator: {
        render: (createElement) =>
            createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },

      countryInput: {
        name: "",
        slug:"",
        status:"active"

      },
      editMode: false,
      formModal: false,
      submitLoading: false,
    };
  },
  mounted() {
    this.getStatistics();
  },

  methods: {
    numberFormat(number) {
      return  new Intl.NumberFormat().format(number)
    },
    async getStatistics() {
      this.cancelToken = this.$http.CancelToken.source();
      const requestData = {
        method: "get",
        url: "/admin/residences/statistics/"+this.$route.params.id,

      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.statistics = data.data;
            console.log(this.statistics)
          })
          .catch((err) => {
            console.log(err);
          });
    },




  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
